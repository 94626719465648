<template>
  <div id="headerSection">
    <router-link to="/">
      <img src="@/assets/images/logo.png" alt="aquastar logo" id="headerLogo" />
    </router-link>
    <div id="mobileMenuIcon" @click="showMenu">
      <div></div>
      <div class="light"></div>
      <div></div>
    </div>
    <div id="headerTextContainer">
      <div id="headerMenu">
        <span class="headerLinks" @click="showCollectionMenu" id="collectionMenuButton">Collection</span>
        <router-link to="/about" class="headerLinks">About</router-link>
        <router-link to="/contact" class="headerLinks">Contact</router-link>
      </div>
      <div id="headerContact">
        <div>+974 7068 9534</div>
        <div>info@aquastarinc.com</div>
      </div>
    </div>
    <ul id="collectionMenuForHeaderPC">
      <li>
        <router-link to="/product/Single Lever Mixer">Single Lever Mixer</router-link>
      </li>
      <li>
        <router-link to="/product/Shattaf Set">Shattaf Set</router-link>
      </li>
      <li>
        <router-link to="/product/Double Lever Mixer">Double Lever Mixer</router-link>
      </li>
      <li>
        <router-link to="/product/Shower Set">Shower Set</router-link>
      </li>
      <li>
        <router-link to="/product/Single Function Water Tap">Single Function Water Tap</router-link>
      </li>
      <li>
        <router-link to="/product/Valve">Valve</router-link>
      </li>
      <li>
        <router-link to="/product/Hose">Hose</router-link>
      </li>
      <li>
        <router-link to="/product/Drainer">Drainer</router-link>
      </li>
      <li>
        <router-link to="/product/Water Filter">Water Filter</router-link>
      </li>
      <li>
        <router-link to="/product/Water Pump">Water Pump</router-link>
      </li>
      <li>
        <router-link to="/product/H- PVC Pipe Fitting">H- PVC Pipe Fitting</router-link>
      </li>
      <li>
        <router-link to="/product/PP-R Fitting">PP-R Fitting</router-link>
      </li>
      <li>
        <router-link to="/product/C-PVC Pipe Fitting">C-PVC Pipe Fitting</router-link>
      </li>
      <li>
        <router-link to="/product/Drainage Fitting">Drainage Fitting</router-link>
      </li>
    </ul>
    <ul id="mobileMenu">
      <li>
        <router-link to="/about" class="headerLinks">About</router-link>
      </li>
      <li @click="showSubMenu = !showSubMenu">
        Collection
        <ul class="subMenu" v-if="showSubMenu">
          <li>
            <router-link to="/product/Single Lever Mixer">Single Lever Mixer</router-link>
          </li>
          <li>
            <router-link to="/product/Shattaf Set">Shattaf Set</router-link>
          </li>
          <li>
            <router-link to="/product/Double Lever Mixer">Double Lever Mixer</router-link>
          </li>
          <li>
            <router-link to="/product/Shower Set">Shower Set</router-link>
          </li>
          <li>
            <router-link to="/product/Single Function Water Tap">Single Function Water Tap</router-link>
          </li>
          <li>
            <router-link to="/product/Valve">Valve</router-link>
          </li>
          <li>
            <router-link to="/product/Hose">Hose</router-link>
          </li>
          <li>
            <router-link to="/product/Drainer">Drainer</router-link>
          </li>
          <li>
            <router-link to="/product/Water Filter">Water Filter</router-link>
          </li>
          <li>
            <router-link to="/product/Water Pump">Water Pump</router-link>
          </li>
          <li>
            <router-link to="/product/H- PVC Pipe Fitting">H- PVC Pipe Fitting</router-link>
          </li>
          <li>
            <router-link to="/product/PP-R Fitting">PP-R Fitting</router-link>
          </li>
          <li>
            <router-link to="/product/C-PVC Pipe Fitting">C-PVC Pipe Fitting</router-link>
          </li>
          <li>
            <router-link to="/product/Drainage Fitting">Drainage Fitting</router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/contact" class="headerLinks">Contact</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "headerSection",
  data() {
    return {
      showSubMenu: false
    };
  },
  watch: {
    $route(to, from) {
      this.showSubMenu = false;
      this.showMenu(false);
      this.showCollectionMenu(false);
    }
  },
  methods: {
    showCollectionMenu(auto = true) {
      var collectionMenu = document.getElementById("collectionMenuForHeaderPC");
      if (!auto) {
        collectionMenu.classList.remove("collectionMenuShow");
      } else {
        if (collectionMenu.classList.contains("collectionMenuShow")) {
          collectionMenu.classList.remove("collectionMenuShow");
        } else {
          collectionMenu.classList.add("collectionMenuShow");
        }
      }
    },
    showMenu(auto = true) {
      var mobileMenu = document.getElementById("mobileMenu");
      if (!auto) {
        mobileMenu.classList.remove("mobileMenuShow");
      } else {
        if (mobileMenu.classList.contains("mobileMenuShow")) {
          mobileMenu.classList.remove("mobileMenuShow");
        } else {
          mobileMenu.classList.add("mobileMenuShow");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#headerSection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeight;
  z-index: 9;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;

  align-items: center;
  color: $darkBlue;
  box-shadow: -8px 10px 13px -13px #6d6d6d;

  #headerLogo {
    height: 30px;
  }
  #headerTextContainer {
    //width: 40%;
    justify-content: space-between;
    align-items: center;
    display: none;
    @include media-md {
      display: flex;
    }
    #headerMenu {
      padding-right: 3rem;
      .headerLinks {
        margin: 0 10px;
        cursor: pointer;
      }
    }
    #headerContact {
      border-left: 1px solid $black;
      padding: 0 0 0 3rem;
      display: flex;
      flex-direction: column;
    }
  }
}
#mobileMenuIcon {
  display: flex;
  flex-direction: column;
  width: 28px;
  cursor: pointer;
  @include media-md {
    display: none;
  }
  div {
    width: 100%;
    height: 4px;
    background-color: $darkBlue;
    margin: 2px;
    border-radius: 1px;
  }
  .light {
    background-color: $blue;
  }
}
#mobileMenu {
  position: absolute;
  margin: 0;
  margin-left: -1rem;
  padding: 1rem;
  top: $headerHeight;
  background-color: white;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 12rem;
  transform: translateX(-100vw);
  transition: transform 0.6s;
  li {
    list-style: none;
    color: $darkBlue;
    margin: 1rem 0;
    list-style-position: inside;
    cursor: pointer;
    font-size: 1.3rem;
  }
  .subMenu {
    li {
      font-size: 1.1rem;
      color: $blue !important;
    }
  }
}
.mobileMenuShow {
  transform: translateX(0) !important;
}
#collectionMenuForHeaderPC {
  margin: 0;
  margin-top: -5px;
  position: absolute;
  left: 0;
  top: $headerHeight;
  background-color: $white;
  padding: 1rem;
  transform: translateX(-100vw);
  transition: transform 0.8s, opacity 0.6s;
  opacity: 0;
  z-index: 8;
  width: 100%;
  box-shadow: -8px 10px 13px -13px #6d6d6d;
  li {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    margin: 5px 0;
    transition: transform 0.5s;
    @include media-sm {
      width: 50%;
      //margin:10px;
    }
    @include media-md {
      width: 25%;
    }
    &:hover {
      transform: translateY(2px);
      color: $blue;
    }
  }
}
.collectionMenuShow {
  transform: translateY(0) !important;
  opacity: 1 !important;
}
</style>

