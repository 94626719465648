<template>
  <div id="homePage">
    <firstSection />
    <newArrivals />
    <ourProducts />
    <div class="commonLeftRightPadding" id="about">
      <h3>About</h3>
      <p>Aquastar is a division of Zeal-it Trading & Contracting. A professionally managed company with over 3 years of wide experience and depth understanding of the products and</p>
      <p>Aquastar is fully committed to high quality performance to ensure customer satisfaction and our approach to business is partnership that provides us wide spectrum loyal customers. Our commitment to be the best product supplier to our valued clients and customers.</p>
      <p>Aquastar provides wide range of sanitary wares such like brass mixers, valves, kitchen sink, water pump, drainage pipes & fittings, cpvc pipes fittings and so on. Aquastar is positioned in Qatar with wide range of customers.</p>
    </div>
  </div>
</template>

<script>
import firstSection from "@/components/pages/home/FirstSection";
import newArrivals from "@/components/pages/home/NewArrivals";
import ourProducts from "@/components/pages/home/OurProducts";
export default {
  name: "home",
  components: {
    firstSection,
    newArrivals,
    ourProducts
  }
};
</script>

<style lang="scss" scoped>
#homePage{
  margin-top:$headerHeight;
}
#about {
  text-align: center;
  margin: 3rem 0;
  color: $darkBlue;
  p {
    width: 90%;
    margin: 1rem auto;
    @include media-md {
      width: 70%;
      margin: 1rem auto;
    }
  }
}
</style>
