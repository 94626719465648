<template>
  <div id="homeSectionNewArrivals" class="commonLeftRightPadding">
    <h3>New Arrivals</h3>
    <div class="newArrivalsContainer">
      <div class="cardContainer">
        <div class="card">
          <img src="@/assets/images/newArraivals/1.jpg">
          <div class="textContainer">
            <p>Double Lever Mixer</p>
            <small>AS0770003</small>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/newArraivals/2.jpg">
          <div class="textContainer">
            <p>Shattaf Set - Gloria</p>
            <small>AS2970111</small>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/newArraivals/3.jpg">
          <div class="textContainer">
            <p>Shower Set</p>
            <small>AS7900018</small>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/newArraivals/4.jpg">
          <div class="textContainer">
            <p>Single Lever Mixer - Hasa</p>
            <small>AS3481034</small>
          </div>
        </div>
      </div>
     <div class="more" @click="showCollectionMenu"> 
        <span>more&nbsp;>></span>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newArrivals",
  methods:{
    showCollectionMenu() {
      var collectionMenu = document.getElementById("collectionMenuForHeaderPC");
      if (collectionMenu.classList.contains("collectionMenuShow")) {
        collectionMenu.classList.remove("collectionMenuShow");
      } else {
        collectionMenu.classList.add("collectionMenuShow");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#homeSectionNewArrivals {
  width: 100vw;
  padding:1rem;
  .newArrivalsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-md {
      flex-direction: row;
      align-items: center;
    }
  }
  .cardContainer {
    width: auto;
    margin: 1rem 0;
    background-color: $white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @include media-md {
      justify-content: space-around;
    }
    .card {
      min-width: 200px;
      max-width: 20%;
      margin: 10px;
      padding: 10px;
      box-shadow: 0px 1px 5px 1px #dedede;
      transition:transform 0.5s;
      img {
        width: 100%;
        height: auto;
      }
      .textContainer {
        p {
          color: $blue;
          margin: 0;
        }
      }
      &:hover{
        transform:translateY(2px);
      }
    }
  }
  .more {
    color: $blue;
    cursor: pointer;
    border-bottom: 1px solid $blue;
    display:none;
    @include media-md {
      transform: rotate(270deg);
      display:block
    }
  }
}
</style>

