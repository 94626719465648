<template>
  <div id="homeSectionNewArrivals" class="commonLeftRightPadding">
    <h3>Our Products</h3>
    <div class="newArrivalsContainer">
      <div class="cardContainer">
        <div class="card">
          <img src="@/assets/images/ourProducts/1.jpg">
          <div class="textContainer">
            <p>Drainer - Sink Waste</p>
            <small>AS3460000</small>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/ourProducts/2.jpg">
          <div class="textContainer">
            <p>Shattaf Set - Amarth</p>
            <small>AS2970081</small>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/ourProducts/3.jpg">
          <div class="textContainer">
            <p>Valve - Angle Valve</p>
            <small>AS2331002</small>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/images/ourProducts/4.jpg">
          <div class="textContainer">
            <p>Valve - Angle Valve</p>
            <small>AS2460019</small>
          </div>
        </div>
      </div>
      <div class="more" @click="showCollectionMenu"> 
        <span>more&nbsp;>></span>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ourProducts",
  methods:{
    showCollectionMenu() {
      var collectionMenu = document.getElementById("collectionMenuForHeaderPC");
      if (collectionMenu.classList.contains("collectionMenuShow")) {
        collectionMenu.classList.remove("collectionMenuShow");
      } else {
        collectionMenu.classList.add("collectionMenuShow");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#homeSectionNewArrivals {
  width: 100vw;
  padding:1rem;
  .newArrivalsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-md {
      flex-direction: row;
      align-items: center;
    }
  }
  .cardContainer {
    width: auto;
    margin: 1rem 0;
    background-color: $white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @include media-md {
      justify-content: space-around;
    }
    .card {
      min-width: 200px;
      max-width: 20%;
      margin: 10px;
      padding: 10px;
      box-shadow: 0px 1px 5px 1px #dedede;
      transition:transform 0.5s;
      img {
        width: 100%;
        height: auto;
      }
      .textContainer {
        p {
          color: $blue;
          margin: 0;
        }
      }
      &:hover{
        transform:translateY(2px);
      }
    }
  
  }
    .more {
      color: $blue;
      cursor: pointer;
      border-bottom: 1px solid $blue;
      display:none;
      @include media-md {
        transform: rotate(270deg);
        display:block
      }
    }
}
</style>

