<template>
  <div id="homeFirstSection">
    <div id="homeFirstSectionTextContainer">
      <h1>
        Caret For Quality,
        <br />Inspire For Life.
      </h1>
    </div>

    <carousel
      :per-page="1"
      :autoplay="true"
      :loop="true"
      id="homeFirstSectionSliderContainer"
      :centerMode="true"
      :autoplayHoverPause="false"
      paginationActiveColor="#0d88ad"
      paginationColor="#94959b"
    >
      <slide class="slide">
        <img src="@/assets/images/slider/1.png" class="slideImage" />
      </slide>
      <slide class="slide">
        <img src="@/assets/images/slider/2.png" class="slideImage" />
      </slide>
      <slide class="slide">
        <img src="@/assets/images/slider/3.png" class="slideImage" />
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "firstSection",
  components: {
    Carousel,
    Slide
  }
};
</script>

<style lang="scss" scoped>
#homeFirstSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include media-md {
    flex-direction: row;
  }
  #homeFirstSectionTextContainer,
  #homeFirstSectionSliderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
  }
  #homeFirstSectionTextContainer {
    width: 100%;
    height: auto;
    padding: 2rem 0;
    @include media-md {
      width: 50%;
      height: 100vh;
      padding: unset;
    }
  }
  #homeFirstSectionSliderContainer {
    width: 100%;
    height: 300px;
    @include media-md {
      width: 50%;
      height: 100vh;
    }
  }
  #homeFirstSectionTextContainer {
    h1 {
      margin: 0;
      padding: 0;
      @include media-md {
        font-size: 4rem;
      }
    }
  }
}
.slide {
  width: inherit;
}
.slideImage {
  height: 50vw;
  width: 40vw;
  display: flex;
  margin: 0 auto;
  align-self: center;
  @include media-md {
    height: 25vw;
  width: 25vw;
  }
}
</style>
