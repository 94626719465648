import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "common" */ './views/About.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "common" */ './views/Contact.vue')
    },
    {
      path: '/career',
      name: 'career',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "career" */ './views/Career.vue')
    },
    {
      path: '/privacyPolicy',
      name: 'privacyPolicy',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "privacyPolicy" */ './views/PrivacyPolicy.vue')
    },
    {
      path: '/termsOfUse',
      name: 'termsOfUse',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "termsOfUse" */ './views/TermsOfUse.vue')
    },
    {
      path: '/product/:product',
      name: 'product',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this routez
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "product" */ './views/Product.vue')
    },
    {
      path: '/products',
      name: 'products',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this routez
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "products" */ './views/Products.vue')
    }
  ]
,scrollBehavior (to, from, savedPosition) {
  return { x: 0, y: 0 };
}})
