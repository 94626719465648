<template>
  <div id="footerSection">
    <div class="certificates">
      <img src="@/assets/icons/ce.png" alt />
      <img src="@/assets/icons/iso.png" alt />
      <img src="@/assets/icons/wqa.png" alt />
      <img src="@/assets/icons/tqm.png" alt />
    </div>

    <div id="footerMenuSection">
      <ul class="footerMenu">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/contact">Contact</router-link>
        </li>
        <li>
          <a href="/catalouge.pdf" target="_blank">Catalouge</a>
        </li>
      </ul>
      <ul class="footerMenu">
        <li>
          <router-link to="/privacyPolicy">Privacy Policy</router-link>
        </li>
        <li>
          <router-link to="/termsOfUse">Terms of Use</router-link>
        </li>
        <li>Blog</li>
      </ul>
      <ul class="footerMenu">
        <li>Sitemap</li>
        <li>Partner with Us</li>
        <li>
          <router-link to="/career">Career</router-link>
        </li>
      </ul>
      <div class="socialMediaIcons footerMenu">
        <a href="https://facebook.com/aquastarinc">
          <img src="@/assets/icons/facebook.png" alt />
        </a>
        <a href="https://instagram.com/aquastarinc">
          <img src="@/assets/icons/insta.png" alt />
        </a>
        <a href="https://linkedin.com/aquastarinc">
          <img src="@/assets/icons/linkedin.png" alt />
        </a>
        <a href="https://twitter.com/aquastarinc">
          <img src="@/assets/icons/twitter.png" alt />
        </a>
      </div>
    </div>

    <div id="footerNote">
      <div>     
      2019 (c) Aquastar <span style="padding-left:10px">All Rights Reserved</span>
      </div> 
      <a href="http://ninetysix.in" style="padding-left:10px">Inked by ninetysix</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerSection"
};
</script>

<style lang="scss" scoped>
#footerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $blue;
  .certificates {
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
    img {
      margin: 20px;
      height: 30px;
      align-self: center;
      @include media-md {
        margin: 30px;
        height: 35px;
      }
    }
  }
  .socialMediaIcons {
    display: flex;
    margin-bottom: 1rem;
    justify-content: flex-start;
    img {
      height: 25px;
      margin: 0 10px 0 0;
      transition: transform 0.3s;
      &:hover {
        transform: rotatey(170deg);
      }
    }
  }
  #footerMenuSection {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: $lightGrey;
    padding: 3rem 10%;
    .footerMenu {
      list-style: none;
      margin: 10px 0;
      width: 40%;
      min-width: 40%;
      max-width: 40%;
      @include media-lg {
        max-width: 200px;
        min-width: 180px;
      }
      li {
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          color: $darkBlue;
        }
      }
    }
  }
  #footerNote {
    background-color: $darkBlue;
    width: 100%;
    color: white;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    flex-direction:column;
    @include media-md{
      flex-direction:row;
    }
  }
}
</style>
