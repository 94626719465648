<template>
  <div id="app">
    <headerSection/>
    <vue-page-transition  name="fade-in-up">
    <router-view/>
    </vue-page-transition>
    <footerSection/>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import headerSection from "@/components/layout/HeaderSection";
import footerSection from "@/components/layout/FooterSection";
export default {
  components:{
    headerSection,
    footerSection
  }
}
</script>
